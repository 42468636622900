import errors from './errors';
import themes from './theme';
import features from './features';
import common from '../common';

export default {
  ...common,
  mobileTicket: 'Mobile Ticket',
  ...features,
  save: 'Salvar',
  username: 'Nome de usuário',
  password: 'Senha',
  login: 'Entrar',
  required: 'Obrigatório',
  light: 'Claro',
  dark: 'Escuro',
  rememberMe: 'Lembrar-me',
  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Política de Privacidade',
  customer: 'Cliente',
  project: 'Projeto',
  isBillable: 'Faturável',
  dispatchDateTime: 'Data',
  orderId: 'Pedido',
  ticketId: 'Bilhete',
  location: 'Localização',
  vehicleTypeName: 'Veículo',
  product: 'Produto',
  products: 'Produtos',
  salesTax: 'Imposto sobre Vendas',
  totalPrice: 'Total',
  extendedPrice: 'Ext',
  destination: 'Destino',
  productId: 'ID do Produto',
  statusCode: 'Status',
  vehicleTypeId: 'ID do Tipo de Veículo',
  locationId: 'ID da Localização',
  customerId: 'ID do Cliente',
  projectId: 'ID do Projeto',
  dispatchTime: 'Hora',
  showAll: 'Mostrar Tudo',
  hideAll: 'Ocultar Tudo',
  undoChanges: 'Desfazer Alterações',
  resetColumns: 'Redefinir Colunas',
  displayRowCount: `Exibindo {count, plural, 
    =0 {0 linhas} 
    one {# linha} 
    other {# linhas} 
  }`,
  columnFilters: 'Filtros de Coluna',
  visible: 'Visível',
  hidden: 'Oculto',
  itemDetail: 'Detalhe do Item',
  quantity: 'Quantidade',
  quantityMaxWaterToAdd: 'Quantidade (Máximo de água para adicionar: {maxWaterQuantity})',

  unloadingMethod: 'Método de Descarga',
  time: 'Tempo',
  eventStatus: 'Status',
  MTQ_abbr: 'm³',
  EA_abbr: 'UN',
  LTR_abbr: 'L',
  YDQ_abbr: 'JDC',
  GLL_abbr: 'GAL',
  MTQ_text: 'Metros Cúbicos',
  M3_text: 'Metros Cúbicos',
  EA_text: 'Cada',
  LTR_text: 'Litros',
  YDQ_text: 'Jardas Cúbicas',
  GLL_text: 'Galões',
  ACR_text: 'Acres',
  HUR_text: 'Hora',
  INH_text: 'Polegadas',
  FOT_text: 'Pé',
  YRD_text: 'Jarda',
  MMT_text: 'Milímetro',
  CMT_text: 'Centímetro',
  MTR_text: 'Metro',
  SMI_text: 'Milha (Milha Estatutária)',
  KMT_text: 'Quilômetro',
  INK_text: 'Polegada Quadrada',
  FTK_text: 'Pé Quadrado',
  YDK_text: 'Jarda Quadrada',
  MIK_text: 'Milha Quadrada',
  CMK_text: 'Centímetro Quadrado',
  MTK_text: 'Metro Quadrado',
  KMK_text: 'Quilômetro Quadrado',
  INQ_text: 'Polegada Cúbica',
  MMQ_text: 'Milímetro Cúbico',
  FTQ_text: 'Pé Cúbico',
  CMQ_text: 'Centímetro Cúbico',
  OZA_text: 'Onça Líquida',
  PT_text: 'Pinta',
  QT_text: 'Quarto',
  MLT_text: 'Mililitro',
  ONZ_text: 'Onça',
  LBR_text: 'Libra',
  STN_text: 'Tonelada',
  GRM_text: 'Grama',
  KGM_text: 'Quilograma',
  TNE_text: 'Tonelada Métrica',
  PS_text: 'PSI',
  MPA_text: 'Megapascal',
  C56_text: 'Newton Por Milímetro Quadrado',
  C62_text: 'Um',
  NL_text: 'Carga',
  MT_text: 'Esteira',
  P1_text: 'Porcento',
  CEL_text: 'Grau Celsius',
  FAH_text: 'Grau Fahrenheit',
  BLL_text: 'Barril',
  CLT_text: 'Centilitro',
  DLT_text: 'Decilitro',
  GLI_text: 'Galão',
  '2U_text': 'Megagrama',
  SA_text: 'Saco',
  '60_text': 'Peso Percentual',
  BG_text: 'Saco',
  SEC_text: 'Segundo',
  MIN_text: 'Minuto',
  '64_text': 'Libra por polegada quadrada - Manômetro',
  SP_text: 'Pacote de Prateleira',
  MMK_text: 'Milímetro Quadrado',
  MTN_text: 'Tonelada Métrica',
  GT_text: 'Gigaton',
  '/NL_text': 'Por carga',
  '/EA_text': 'Por quantidade',
  '/H/ + h_text': 'Por hora',
  '/YQ/ + YDQ_text': 'Por jarda cúbica',
  '/MQ/ + MTQ_text': 'Por metro cúbico',
  '/MN/ + MIN_text': 'Por minuto',
  '/MT_text': 'Por metro',
  DSG_text: 'Dosagem',
  '/C_text': 'Por peso 100',
  '64_2_text': 'Específico',
  MTQ: `{value, plural,
    =0 {0 m³}
    one {1 m³}
    other {# m³}
  }`,
  M3: `{value, plural,
    =0 {0 m³}
    one {1 m³}
    other {# m³}
  }`,
  LTR: `{value, plural,
    =0 {0 Litros}
    one {1 Litro}
    other {# Litros}
  }`,
  TO: `{value, plural,
    =0 {0 Toneladas}
    one {1 Tonelada}
    other {# Toneladas}
  }`,
  ITEM: `{value, plural,
    =0 {0 Itens}
    one {1 Item}
    other {# Itens}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 Jardas Cúbicas}
    one {1 Jarda Cúbica}
    other {# Jardas Cúbicas}
  }`,
  YDQ: `{value, plural,
    =0 {0 Jardas Cúbicas}
    one {1 Jarda Cúbica}
    other {# Jardas Cúbicas}
  }`,
  GLL: `{value, plural,
    =0 {0 Galões}
    one {1 Galão}
    other {# Galões}
  }`,
  EA: `{value, plural,
    =0 {0 Cada}
    one {1 Cada}
    other {# Cada}
  }`,
  MIN: `{value, plural,
    =0 {0 Minutos}
    one {1 Minuto}
    other {# Minutos}
  }`,
  ACR: `{value, plural,
    =0 {0 Acres}
    one {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Horas}
    one {1 Hora}
    other {# Horas}
  }`,
  INH: `{value, plural,
    =0 {0 Polegadas}
    one {1 Polegada}
    other {# Polegadas}
  }`,
  FOT: `{value, plural,
    =0 {0 Pés}
    one {1 Pé}
    other {# Pés}
  }`,
  YRD: `{value, plural,
    =0 {0 Jardas}
    one {1 Jarda}
    other {# Jardas}
  }`,
  MMT: `{value, plural,
    =0 {0 Milímetros}
    one {1 Milímetro}
    other {# Milímetros}
  }`,
  CMT: `{value, plural,
    =0 {0 Centímetros}
    one {1 Centímetro}
    other {# Centímetros}
  }`,
  MTR: `{value, plural,
    =0 {0 Metros}
    one {1 Metro}
    other {# Metros}
  }`,
  SMI: `{value, plural,
    =0 {0 Milhas (Milhas Estatutárias)}
    one {1 Milha (Milha Estatutária)}
    other {# Milhas (Milhas Estatutárias)}
  }`,
  KMT: `{value, plural,
    =0 {0 Quilômetros}
    one {1 Quilômetro}
    other {# Quilômetros}
  }`,
  INK: `{value, plural,
    =0 {0 Polegadas Quadradas}
    one {1 Polegada Quadrada}
    other {# Polegadas Quadradas}
  }`,
  FTK: `{value, plural,
    =0 {0 Pés Quadrados}
    one {1 Pé Quadrado}
    other {# Pés Quadrados}
  }`,
  YDK: `{value, plural,
    =0 {0 Jardas Quadradas}
    one {1 Jarda Quadrada}
    other {# Jardas Quadradas}
  }`,
  MIK: `{value, plural,
    =0 {0 Milhas Quadradas}
    one {1 Milha Quadrada}
    other {# Milhas Quadradas}
  }`,
  CMK: `{value, plural,
    =0 {0 Centímetros Quadrados}
    one {1 Centímetro Quadrado}
    other {# Centímetros Quadrados}
  }`,
  MTK: `{value, plural,
    =0 {0 Metros Cúbicos}
    one {1 Metro Cúbico}
    other {# Metros Cúbicos}
  }`,
  KMK: `{value, plural,
    =0 {0 Quilômetros Quadrados}
    one {1 Quilômetro Quadrado}
    other {# Quilômetros Quadrados}
  }`,
  INQ: `{value, plural,
    =0 {0 Polegadas Cúbicas}
    one {1 Polegada Cúbica}
    other {# Polegadas Cúbicas}
  }`,
  MMQ: `{value, plural,
    =0 {0 Milímetros Cúbicos}
    one {1 Milímetro Cúbico}
    other {# Milímetros Cúbicos}
  }`,
  FTQ: `{value, plural,
    =0 {0 Pés Cúbicos}
    one {1 Pé Cúbico}
    other {# Pés Cúbicos}
  }`,
  CMQ: `{value, plural,
    =0 {0 Centímetros Cúbicos}
    one {1 Centímetro Cúbico}
    other {# Centímetros Cúbicos}
  }`,
  OZA: `{value, plural,
    =0 {0 Onças Fluidas}
    one {1 Onça Fluida}
    other {# Onças Fluidas}
  }`,
  PT: `{value, plural,
    =0 {0 Pintas}
    one {1 Pinta}
    other {# Pintas}
  }`,
  QT: `{value, plural,
    =0 {0 Quartos}
    one {1 Quarto}
    other {# Quartos}
  }`,
  MLT: `{value, plural,
    =0 {0 Mililitros}
    one {1 Mililitro}
    other {# Mililitros}
  }`,
  ONZ: `{value, plural,
    =0 {0 Onças}
    one {1 Onça}
    other {# Onças}
  }`,
  LBR: `{value, plural,
    =0 {0 Libras}
    one {1 Libra}
    other {# Libras}
  }`,
  STN: `{value, plural,
    =0 {0 Toneladas}
    one {1 Tonelada}
    other {# Toneladas}
  }`,
  GRM: `{value, plural,
    =0 {0 Gramas}
    one {1 Grama}
    other {# Gramas}
  }`,
  KGM: `{value, plural,
    =0 {0 Quilogramas}
    one {1 Quilograma}
    other {# Quilogramas}
  }`,
  TNE: `{value, plural,
    =0 {0 Toneladas}
    one {1 Tonelada}
    other {# Toneladas}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascais}
    one {1 Megapascal}
    other {# Megapascais}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons por Milímetro Quadrado}
    one {1 Newton por Milímetro Quadrado}
    other {# Newtons por Milímetro Quadrado}
  }`,
  C62: `{value, plural,
    =0 {0 Um}
    one {1 Um}
    other {# Um}
  }`,
  NL: `{value, plural,
    =0 {0 Cargas}
    one {1 Carga}
    other {# Cargas}
  }`,
  MT: `{value, plural,
    =0 {0 Esteiras}
    one {1 Esteira}
    other {# Esteiras}
  }`,
  P1: `{value, plural,
    =0 {0 Porcento}
    one {1 Porcento}
    other {# Porcento}
  }`,
  CEL: `{value, plural,
    =0 {0 Graus Celsius}
    one {1 Grau Celsius}
    other {# Graus Celsius}
  }`,
  FAH: `{value, plural,
    =0 {0 Graus Fahrenheit}
    one {1 Grau Fahrenheit}
    other {# Graus Fahrenheit}
  }`,
  BLL: `{value, plural,
    =0 {0 Barris}
    one {1 Barril}
    other {# Barris}
  }`,
  CLT: `{value, plural,
    =0 {0 Centilitros}
    one {1 Centilitro}
    other {# Centilitros}
  }`,
  DLT: `{value, plural,
    =0 {0 Decilitros}
    one {1 Decilitro}
    other {# Decilitros}
  }`,
  GLI: `{value, plural,
    =0 {0 Galões}
    one {1 Galão}
    other {# Galões}
  }`,
  '2U': `{value, plural,
    =0 {0 Megagramas}
    one {1 Megagrama}
    other {# Megagramas}
  }`,
  SA: `{value, plural,
    =0 {0 Sacos}
    one {1 Saco}
    other {# Sacos}
  }`,
  60: `{value, plural,
    =0 {0 Porcento de Peso}
    one {1 Porcento de Peso}
    other {# Porcento de Peso}
  }`,
  BG: `{value, plural,
    =0 {0 Sacolas}
    one {1 Sacola}
    other {# Sacolas}
  }`,
  SEC: `{value, plural,
    =0 {0 Segundos}
    one {1 Segundo}
    other {# Segundos}
  }`,
  64: `{value, plural,
    =0 {0 Libras por polegada quadrada - Manômetro}
    one {1 Libra por polegada quadrada - Manômetro}
    other {# Libras por polegada quadrada - Manômetro}
  }`,
  SP: `{value, plural,
    =0 {0 Pacotes de Prateleira}
    one {1 Pacote de Prateleira}
    other {# Pacotes de Prateleira}
  }`,
  MMK: `{value, plural,
    =0 {0 milímetros quadrados}
    one {1 milímetro quadrado}
    other {# milímetros quadrados}
  }`,
  MTN: `{value, plural,
    =0 {0 toneladas métricas}
    one {1 tonelada métrica}
    other {# toneladas métricas}
  }`,
  GT: `{value, plural,
    =0 {0 gigatoneladas}
    one {1 gigatonelada}
    other {# gigatoneladas}
  }`,
  '/NL': `{value, plural,
    =0 {0 por carga}
    one {1 por carga}
    other {# por carga}
  }`,
  '/EA': `{value, plural,
    =0 {0 por quantidade}
    one {1 por quantidade}
    other {# por quantidade}
  }`,
  '/H/ + h': `{value, plural,
    =0 {0 por hora}
    one {1 por hora}
    other {# por hora}
  }`,
  '/YQ/ + YDQ': `{value, plural,
    =0 {0 por jarda cúbica}
    one {1 por jarda cúbica}
    other {# por jarda cúbica}
  }`,
  '/MQ/ + MTQ': `{value, plural,
    =0 {0 por metro cúbico}
    one {1 por metro cúbico}
    other {# por metro cúbico}
  }`,
  '/MN/ + MIN': `{value, plural,
    =0 {0 por minuto}
    one {1 por minuto}
    other {# por minuto}
  }`,
  '/MT': `{value, plural,
    =0 {0 por metro}
    one {1 por metro}
    other {# por metro}
  }`,
  DSG: `{value, plural,
    =0 {0 dosagem}
    one {1 dosagem}
    other {# dosagem}
  }`,
  '/C': `{value, plural,
    =0 {0 por 100 peso}
    one {1 por 100 peso}
    other {# por 100 peso}
  }`,
  '64_2': `{value, plural,
    =0 {0 Específico}
    one {1 Específico}
    other {# Específico}
  }`,
  LTR_short: `{value, plural,
  =0 {0 L}
  one {1 L}
  other {# L}
}`,
  TO_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# Tn}
}`,
  ITEM_short: `{value, plural,
    =0 {0 Itens}
    one {1 Item}
    other {# Itens}
}`,
  GLL_short: `{value, plural,
  =0 {0 GAL}
  one {1 GAL}
  other {# GAL}
}`,
  EA_short: `{value, plural,
  =0 {0 EA}
  one {1 EA}
  other {# EA}
}`,
  MIN_short: `{value, plural,
  =0 {0 MIN}
  one {1 MIN}
  other {# MIN}
}`,
  ACR_short: `{value, plural,
  =0 {0 AC}
  one {1 AC}
  other {# AC}
}`,
  HUR_short: `{value, plural,
  =0 {0 HR}
  one {1 HR}
  other {# HR}
}`,
  INH_short: `{value, plural,
  =0 {0 IN}
  one {1 IN}
  other {# IN}
}`,
  FOT_short: `{value, plural,
  =0 {0 FT}
  one {1 FT}
  other {# FT}
}`,
  YRD_short: `{value, plural,
  =0 {0 YD}
  one {1 YD}
  other {# YD}
}`,
  MMT_short: `{value, plural,
  =0 {0 MM}
  one {1 MM}
  other {# MM}
}`,
  CMT_short: `{value, plural,
  =0 {0 CM}
  one {1 CM}
  other {# CM}
}`,
  MTR_short: `{value, plural,
  =0 {0 M}
  one {1 M}
  other {# M}
}`,
  SMI_short: `{value, plural,
  =0 {0 Miles (ST MI)}
  one {1 Mile ((ST MI)}
  other {# Miles ((ST MI)}
}`,
  KMT_short: `{value, plural,
  =0 {0 KM}
  one {1 KM}
  other {# KM}
}`,
  INK_short: `{value, plural,
  =0 {0 SQ IN}
  one {1 SQ IN}
  other {# SQ IN}
}`,
  FTK_short: `{value, plural,
  =0 {0 FT2}
  one {1 FT2}
  other {# FT2}
}`,
  YDK_short: `{value, plural,
  =0 {0 SQ YD}
  one {1 SQ YD}
  other {# SQ YD}
}`,
  MIK_short: `{value, plural,
  =0 {0 SQ MI}
  one {1 SQ MI}
  other {# SQ MI}
}`,
  CMK_short: `{value, plural,
  =0 {0 CM2}
  one {1 CM2}
  other {# CM2}
}`,
  MTK_short: `{value, plural,
  =0 {0 M3}
  one {1 M3}
  other {# M3}
}`,
  KMK_short: `{value, plural,
  =0 {0 KM2}
  one {1 KM2}
  other {# KM2}
}`,
  INQ_short: `{value, plural,
  =0 {0 CU IN}
  one {1 CU IN}
  other {# CU IN}
}`,
  MMQ_short: `{value, plural,
  =0 {0 MM3}
  one {1 MM3}
  other {# MM3}
}`,
  FTQ_short: `{value, plural,
  =0 {0 FT3}
  one {1 FT3}
  other {# FT3}
}`,
  CMQ_short: `{value, plural,
  =0 {0 CM3}
  one {1 CM3}
  other {# CM3}
}`,
  OZA_short: `{value, plural,
  =0 {0 FL OZ}
  one {1 FL OZ}
  other {# FL OZ}
}`,
  PT_short: `{value, plural,
  =0 {0 PT}
  one {1 PT}
  other {# PT}
}`,
  QT_short: `{value, plural,
  =0 {0 QT}
  one {1 QT}
  other {# QT}
}`,
  MLT_short: `{value, plural,
  =0 {0 mL}
  one {1 mL}
  other {# mL}
}`,
  ONZ_short: `{value, plural,
  =0 {0 OZ}
  one {1 OZ}
  other {# OZ}
}`,
  LBR_short: `{value, plural,
  =0 {0 LB}
  one {1 LB}
  other {# LB}
}`,
  STN_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# TN}
}`,
  GRM_short: `{value, plural,
  =0 {0 G}
  one {1 G}
  other {# G}
}`,
  KGM_short: `{value, plural,
  =0 {0 KG}
  one {1 KG}
  other {# KG}
}`,
  TNE_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# TN}
}`,
  PS_short: `{value, plural,
  =0 {0 PSI}
  one {1 PSI}
  other {# PSI}
}`,
  MPA_short: `{value, plural,
  =0 {0 MPa}
  one {1 MPa}
  other {# MPa}
}`,
  C56_short: `{value, plural,
    =0 {0 Newtons por Milímetro Quadrado}
    one {1 Newton por Milímetro Quadrado}
    other {# Newtons por Milímetro Quadrado}
}`,
  C62_short: `{value, plural,
    =0 {0 Um}
    one {1 Um}
    other {# Um}
}`,
  NL_short: `{value, plural,
    =0 {0 Cargas}
    one {1 Carga}
    other {# Cargas}
}`,
  MT_short: `{value, plural,
  =0 {0 Mats}
  one {1 Mat}
  other {# Mats}
}`,
  P1_short: `{value, plural,
  =0 {0 %}
  one {1 %}
  other {# %}
}`,
  CEL_short: `{value, plural,
  =0 {0 C}
  one {1 C}
  other {# C}
}`,
  FAH_short: `{value, plural,
  =0 {0 F}
  one {1 F}
  other {# F}
}`,
  BLL_short: `{value, plural,
  =0 {0 BBL}
  one {1 BBL}
  other {# BBL}
}`,
  CLT_short: `{value, plural,
  =0 {0 cL}
  one {1 cL}
  other {# cL}
}`,
  DLT_short: `{value, plural,
  =0 {0 dL}
  one {1 dL}
  other {# dL}
}`,
  GLI_short: `{value, plural,
  =0 {0 GAL}
  one {1 GAL}
  other {# GAL}
}`,
  '2U_short': `{value, plural,
    =0 {0 Megagramas}
    one {1 Megagrama}
    other {# Megagramas}
}`,
  SA_short: `{value, plural,
    =0 {0 Sacos}
    one {1 Saco}
    other {# Sacos}
}`,
  '60_short': `{value, plural,
    =0 {0 Porcento de Peso}
    one {1 Porcento de Peso}
    other {# Porcento de Peso}
}`,
  BG_short: `{value, plural,
    =0 {0 Sacolas}
    one {1 Sacola}
    other {# Sacolas}
}`,
  SEC_short: `{value, plural,
  =0 {0 SEC}
  one {1 SEC}
  other {# SEC}
}`,
  '64_short': `{value, plural,
    =0 {0 Libras por polegada quadrada - Manômetro}
    one {1 Libra por polegada quadrada - Manômetro}
    other {# Libras por polegada quadrada - Manômetro}
}`,
  SP_short: `{value, plural,
    =0 {0 Pacotes de Prateleira}
    one {1 Pacote de Prateleira}
    other {# Pacotes de Prateleira}
}`,
  MMK_short: `{value, plural,
    =0 {0 milímetros quadrados}
    one {1 milímetro quadrado}
    other {# milímetros quadrados}
}`,
  MTN_short: `{value, plural,
  =0 {0 MT}
  one {1 MT}
  other {# MT}
}`,
  GT_short: `{value, plural,
  =0 {0 GT}
  one {1 GT}
  other {# GT}
}`,
  '/NL_short': `{value, plural,
=0 {0 /Load}
one {1 /Load}
other {# /Load}
}`,
  '/EA_short': `{value, plural,
=0 {0 /EA}
one {1 /EA}
other {# /EA}
}`,
  '/H/ + h_short': `{value, plural,
=0 {0 /Hour}
one {1 /Hour}
other {# /Hour}
}`,
  '/YQ/ + YDQ_short': `{value, plural,
=0 {0 /CY}
one {1 /CY}
other {# /CY}
}`,
  '/MQ/ + MTQ_short': `{value, plural,
=0 {0 /M3}
one {1 /M3}
other {# /M3}
}`,
  '/MN/ + MIN_short': `{value, plural,
=0 {0 /MIN}
one {1 /MIN}
other {# /MIN}
}`,
  '/MT_short': `{value, plural,
=0 {0 /M}
one {1 /M}
other {# /M}
}`,
  DSG_short: `{value, plural,
=0 {0 Dosage}
one {1 Dosage}
other {# Dosage}
}`,
  '/C_short': `{value, plural,
=0 {0 /C}
one {1 /C}
other {# /C}
}`,
  '64_2_short': `{value, plural,
=0 {0 Specific}
one {1 Specific}
other {# Specific}
}`,
  mixIdLabel: 'ID/Mistura Descrição',
  orderedQtyLabel: 'Quantidade Pedida',
  loadedQtyLabel: 'Quantidade Carregada',
  addedByCustomer: 'Adicionado Pelo Cliente',
  PRINTED: 'Emitido',
  LOADING_STARTED: 'Iniciar Carregamento',
  LOADING_COMPLETE: 'Finalizar Carregamento',
  TO_JOB: 'Saiu da Planta',
  ARRIVE_JOB: 'Chegada no Local',
  UNLOADING: 'Iniciar Descarga',
  END_UNLOADING: 'Finalizar Descarga',
  LEAVE_JOB: 'Deixar o Trabalho',
  IN_YARD: 'No Pátio',
  PUMPING_STARTED: 'Iniciar Bombeamento',
  PUMPING_COMPLETE: 'Finalizar Bombeamento',
  READY_TO_PUMP: 'Pronto para Bombear',
  FIRST_WCCONTACT: 'Primeiro Contato Água Cimento',
  plantName: 'Nome da Planta',
  plantAddress: 'Endereço da Planta',
  loadingScheduleStart: 'Horário Programado para Carregamento',
  deliveryAddress: 'Endereço de Entrega',
  phoneNumber: 'Número de Telefone',
  unloadingScheduleStart: 'Horário Planejado para Descarga (ETA)',
  agreedUnloading: 'Horários de Descarga Acordados',
  deliveryInformation: 'Informações de Entrega',
  next: 'Próximo',
  material: 'Material',
  loadingPoint: 'Ponto de Carregamento',
  unloadingPoint: 'Ponto de Descarga',
  comment: 'Comentário',
  sapDeliveryNumber: 'Número de Entrega SAP (Número BCC)',
  salesOrderNumber: 'Número do Pedido de Venda e Item da Linha',
  wcDateTime: 'Data e Hora do Contato Água/Cimento',
  po: 'Número do PO',
  orderedByName: 'Pedido Feito por Nome',
  orderedByPhone: 'Pedido Feito por Número de Telefone',
  resourceId: 'ID do Recurso',
  totalLoadedQty: 'Quantidade Total Carregada Incluindo Esta Carga',
  spacing: 'Espaço de Carga',
  deliveryFlow: 'Fluxo de Entrega',
  timeOnSite: 'Tempo Total no Local/Tempo de Descarga Pedido',
  salesText: 'Texto de Vendas (ID do Material)',
  strengthClass: 'Classe de Resistência',
  environmentExposure: 'Exposição ao Ambiente',
  metExposureClasses: 'Classes de Exposição Atendidas',
  dmax: 'Dmax',
  standardCert: 'Padrão (certificação)',
  workabilityTarget: 'Objetivo de Trabalhabilidade do Cliente',
  stoneQuality: 'Qualidade da Pedra',
  sandQuality: 'Qualidade da Areia',
  targetWC: 'Razão Água/Cimento Alvo da Receita',
  controlClass: 'Classe de Controle',
  chlorideClass: 'Classe de Cloreto',
  typeAndStrength1: 'Tipo de Cimento e Classe de Resistência 1',
  typeAndStrength2: 'Tipo de Cimento e Classe de Resistência 2',
  typeOfAddition: 'Tipo de Adição',
  typeOfAdmixture: 'Tipo de Aditivo',
  orderedTemp: 'Temperatura Pedida',
  fibers1: 'Tipo e Conteúdo de Fibras 1',
  fibers2: 'Tipo e Conteúdo de Fibras 2',
  calculatedMaturityTime: 'Tempo de Maturidade Calculado a partir do Tempo de Contato Água/Cimento',
  mixIdentity: 'Identidade da Mistura',
  grade: 'Grau',
  mixType: 'Tipo de Mistura',
  maxAggSize: 'Tamanho Máx. Agregado',
  maxAggType: 'Tipo de Agregado Máx.',
  cementType: 'Tipo de Cimento',
  admixtures: 'Aditivos',
  addmixDescription: 'Descrição do Aditivo',
  slump: 'Abatimento',
  minCementContent: 'Conteúdo Mínimo de Cimento',
  maxWatCemRatio: 'Razão Máxima Água/Cimento',
  maxWaterToAdd: 'Máxima Água a Adicionar',
  mortarMinWorkingLife: 'Vida Útil Mínima do Argamassa',
  code: 'Código',
  dcClass: 'Classe dc',
  chloride: 'Cloreto',
  signedByHanson: 'Assinado Por Hanson',
  warmStamp: 'Selo Quente',
  coldStamp: 'Selo Frio',
  containsSikatard: 'Contém Sikatard',
  containsDelvo: 'Contém Delvo',
  generalDelivery: 'Informações Gerais de Entrega',
  materialInfo: 'Informações do Material',
  water: 'Água',
  materialService: 'Material / Serviço',
  returnedMaterial: 'Material Devolvido',
  returnedConcrete: 'Concreto Devolvido',
  waterAdded: 'Água Adicionada',
  concreteOnTruck: 'Concreto no Caminhão',
  concreteOnTruckUom: 'UOM Padrão de Concreto no Caminhão',
  reason: 'Razão',
  customerAddedWater: 'Água Adicionada pelo Cliente',
  addWater: 'Adicionar Água',
  addedAt: 'Adicionado em',
  addMaterialService: 'Adicionar Material/Serviço',
  customerAddedProduct: 'Produto Adicionado pelo Cliente',
  addReturnedMaterial: 'Adicionar Material Devolvido',
  leftover: 'Sobras',
  submitAcceptance: 'Enviar Aceitação',
  accepted: 'Aceito',
  rejected: 'Rejeitado',
  customerAcceptance: 'Aceitação',
  finalize: 'Finalizar',
  materialSummary: 'Resumo do Material',
  mix: 'Mistura',
  ordered: 'Pedido',
  loaded: 'Carga de Entrega',
  totalOrdered: 'Quantidade Total Pedida',
  loadedOrderQuantity: 'Quantidade de Pedido Carregada',
  loadedOrder: 'Pedido Carregado',
  driverComment: 'Comentário do Motorista',
  customerComment: 'Comentário do Cliente',
  editComment: 'Editar Comentário',
  addComment: 'Adicionar Comentário',
  additionalInfo: 'Info Adicional',
  onBoard: 'A Bordo',
  driver: 'Motorista',
  commentInstruction: 'Instrução de Comentário',
  signatureInstruction: 'Assinatura do Cliente',
  customerSignature: 'Assinatura do Cliente',
  general: 'Geral',
  status: 'Status',
  updateStatusTime: 'Atualizar Hora do Status',
  delete: 'Deletar',
  cancel: 'Cancelar',
  back: 'Voltar',
  add: 'Adicionar',
  edit: 'Editar',
  done: 'Concluído',
  ok: 'OK',
  decline: 'Recusar',
  Submit: 'Enviar',
  submit: 'Enviar',
  lastRefresh: 'Última Atualização',
  refreshEnabled: 'Atualização Ativada',
  id: 'ID',
  name: 'Nome',
  invoiceable: 'Faturável',
  provideSignature: 'Fornecer Assinatura',
  ticket: 'Bilhete',
  details: 'Detalhes',
  qrCode: 'Código QR',
  driverAcceptanceReason: 'Razão',
  driverAcceptance: 'Aceitação do Motorista',
  'payment-method': 'Métodos de Pagamento',
  'add-payment-method': 'Adicionar Método de Pagamento',
  'reason-code': 'Código de Motivo',
  'add-reason-code': 'Adicionar Código de Motivo',
  'qr-code': 'Código QR',
  'unloading-method': 'Métodos de Descarga',
  'add-unloading-method': 'Adicionar Método de Descarga',
  'driver-comment': 'Comentários do Motorista',
  'add-driver-comment': 'Adicionar Comentário do Motorista',
  'driver-sellable': 'Vendável pelo Motorista',
  vehicle: 'Veículos',
  'add-vehicle': 'Adicionar Veículo',
  'vehicle-type': 'Tipos de Veículo',
  vehicleTypes: 'Tipos de Veículo',
  vehicleType: 'Tipo de Veículo',
  'add-vehicle-type': 'Adicionar Tipo de Veículo',
  countries: 'Países',
  country: 'País',
  type: 'Tipo',
  uom: 'UOM',
  mandatory: 'Obrigatório',
  qrTemplate: 'Modelo de Código QR',
  includeMixInfo: 'Incluir Informações de Mix no Código QR',
  includeTicketEvents: 'Incluir Eventos do Bilhete no Código QR',
  quantityField: 'Habilitar Campo de Quantidade',
  concreteOnTruckField: 'Habilitar Campo de Concreto no Caminhão',
  materialOnTruckField: 'Habilitar Campo de Material no Caminhão',
  timeField: 'Habilitar Campo de Tempo',
  reasonField: 'Habilitar Campo de Motivo',
  driverInstructions: 'Instruções para o Motorista',
  waitingForTicket: 'Aguardando pelo bilhete...',
  createTestTicketForTruck: 'Criar Bilhete de Teste para o Caminhão',
  exceedsLoadedQuantity: 'O valor não pode exceder a quantidade carregada de {loadedQuantity}.',
  maxQuantity: 'Quantidade Máxima',
  maxQuantityError: 'A quantidade não pode exceder a quantidade máxima definida de {maxQty}',
  maxValueError: 'O valor não pode exceder o máximo definido de {maxQty}',
  minValueError: 'O valor não pode ser menor que o mínimo definido de {minQty}',
  notAuthorized: 'Não Autorizado',
  enterTruckNumber: 'Digite o Número do Caminhão',
  setTruckNumber: 'Definir Número do Caminhão',
  commentList: 'Lista de Comentários',
  list: 'Lista',
  gridPreferences: 'Preferências da Grade',
  loadingUnloadingPoints: 'Pontos de Carga/Descarga',
  activity: 'Atividade',
  crn: 'CRN',
  update: 'Atualizar',
  CONCRETE: 'Concreto',
  WATER: 'Água',
  ADDITIVE: 'Aditivo',
  uomCode: 'UOM Padrão',
  setup_product: 'Produtos',
  setup_vehicle: 'Veículos',
  setup_carriers: 'Transportadoras',
  carriers: 'Transportadoras',
  'submit-signature-button': 'Aceitar',
  'cancel-signature-button': 'Cancelar',
  'product-type': 'Tipos de Produto',
  'add-product-type': 'Adicionar Tipo de Produto',
  'add-product': 'Adicionar Produto',
  'setup_driver-comment': 'Comentários do Motorista',
  'setup_product-type': 'Tipos de Produto',
  'setup_qr-code': 'Código QR',
  'setup_reason-code': 'Códigos de Motivo',
  'setup_unloading-method': 'Métodos de Descarga',
  'setup_entity-mt': 'Empresa',
  'setup_vehicle-type': 'Tipos de Veículo',
  'setup_data-retentions': 'Retenção de Dados',
  'add-data-retentions': 'Adicionar Retenção de Dados',
  'data-retentions': 'Retenção de Dados',
  'add-carriers': 'Adicionar Transportadoras',
  'setup_concrete-classes': 'Conformidade de Material',
  'add-concrete-classes': 'Adicionar Conformidade de Material',
  'concrete-classes': 'Conformidade de Material',
  'setup_forms-and-waivers': 'Formulários e Isenções',
  'add-forms-and-waivers': 'Adicionar Formulários e Isenções',
  'forms-and-waivers': 'Formulários e Isenções',
  companyFleet: 'Frota da Empresa',
  category: 'Categoria',
  interval: 'Intervalo',
  orders: 'Pedidos e Bilhetes',
  '30days': '30 Dias',
  '60days': '60 Dias',
  '90days': '90 Dias',
  '1year': '1 Ano',
  '3years': '3 Anos',
  waterMandatory: 'Água Obrigatória',
  qrCodeTemplate: 'Modelo de Código QR',
  waterConfig: 'Campos Opcionais',
  waterProduct: 'Água',
  waterProductType: 'Tipo de Produto de Água',
  defaultWaterProduct: 'Produto de Água Padrão',
  invalidTemplate: 'Modelo Inválido',
  siteAddress: 'Endereço do Local',
  siteContactName: 'Nome do Contato do Local',
  siteContactPhoneNumber: 'Número de Telefone do Contato do Local',
  'clear-signature-button': 'Limpar Assinatura',
  customerCommentEnabled: 'Habilitar Comentário do Cliente',
  driverCommentEnabled: 'Habilitar Comentário do Motorista',
  driverCommentMaxCharacters: 'Comprimento Máximo',
  customerCommentMaxCharacters: 'Comprimento Máximo',
  timeZone: 'Fuso Horário',
  driverDidNotAdd: 'Motorista Não Adicionou',
  maxLineItems: 'Número Máximo de Itens de Linha',
  oneLineItem: 'Deve ter pelo menos 1 item de linha',
  signatureSiteContact: 'Nome do Cliente',
  ticketEvents: 'Eventos do Bilhete',
  hideEvent: 'Ocultar Evento',
  editable: 'Editável',
  ticketDetail: 'Bilhete Nº {value}',
  statusTimes: 'Horários de Status',
  materialAndDeliveryInfo: 'Informações de Material e Entrega',
  customerRejection: 'Rejeição do Cliente',
  'submit-rejection-button': 'Enviar Rejeição',
  'no-signature': 'Sem Assinatura',
  accept: 'Aceitar',
  reject: 'Rejeitar Carga',
  signature: 'Assinatura',
  unFinalizePassword: 'Desfinalizar Senha',
  waterInWorkflow: 'Habilitar Etapa de Água no Fluxo de Trabalho',
  multicountry: 'Multinacional',
  timestampAfterSequence: 'AVISO: O tempo não deve ser anterior ao carimbo de data/hora anterior',
  timestampBeforeSequence: 'AVISO: O tempo não deve ser posterior ao próximo carimbo de data/hora',
  noRestQuantity: 'Nenhum material devolvido',
  returnedMaterialMandatory: 'Deve Interagir com a Etapa de Material Devolvido',
  driverCommentMaxChars: 'O comprimento não pode exceder o máximo de {maxLength} caracteres.',
  QrCodeEnabled: 'Habilitar Código QR',
  QrMethod_linkBased: 'Baseado em Link',
  QrMethod_contentBased:
    'Baseado em Conteúdo, NOTA: Códigos QR Baseados em Conteúdo têm limitações de caracteres que podem causar problemas além de 1024 caracteres.',
  QrMethod_contentBasedWarning:
    'NOTA: Este código QR tem {amount} caracteres que podem não ser legíveis por alguns telefones.',
  returnedMaterialInWorkflow: 'Habilitar Etapa de Material Devolvido no Fluxo de Trabalho',
  materialServiceInWorkflow: 'Habilitar Etapa de Material/Serviço no Fluxo de Trabalho',
  totalPumpedQuantity: 'Quantidade Total Bombeada',
  concrete: 'Concreto',
  materialComplianceEnabled: 'Habilitar Conformidade de Material',
  pumpStatusTimes: 'Tempos de Status da Bomba',
  pollingInterval: 'Intervalo de Pesquisa (segundos)',
  tenSecs: 'O intervalo deve ser de pelo menos 10 segundos',
  materialCompliance: 'Conformidade de Material',
  materialCompliance_notFound: 'Conformidade do material não encontrada',
  consistenceValue: 'Consistência - Abatimento',
  consistenceIsCompliant: 'A consistência está em conformidade com BS8500-2',
  airContentValue: 'Conteúdo de Ar',
  airContentIsCompliant: 'O Conteúdo de Ar está em conformidade com BS8500-2',
  consistenceIsCompliantShort: 'A consistência está em conformidade',
  airContentIsCompliantShort: 'O Conteúdo de Ar está em conformidade',
  compliance: 'Conformidade de Material com BS8500-2',
  termsAndConditionsEnabled: 'Habilitar formulário de Termos e Condições',
  termsAndConditions: 'Termos e Condições',
  damageWaiverEnabled: 'Habilitar formulário de Isenção de Danos',
  damageWaiver: 'Isenção de Danos',
  damageWaiverAcknowledged: 'Isenção de Danos Reconhecida',
  dynamicWaiver: 'Isenção Dinâmica',
  dynamicWaiverEnabled: 'Habilitar formulário de Isenção Dinâmica',
  ticketListEnabled: 'Habilitar Lista de Bilhetes',
  clearTicketAfterSubmission: 'Limpar Bilhete Após Submissão',
  keepTicketOpenAfterSubmission: 'Manter Bilhete Aberto Após Submissão',
  displayTicketList: 'Exibir Lista de Bilhetes',
  supportedLanguages: 'Idiomas Suportados',
  allow: 'Permitir',
  default: 'Padrão',
  isCertificationLossTriggerForReason: 'Motivo para Nota de Perda de Certificação',
  isCertificationLossTriggerForProduct: 'Produto para Nota de Perda de Certificação',
  translations: 'Traduções',
  family_name: 'Sobrenome',
  given_name: 'Nome',
  email: 'Email',
  role: 'Função',
  inviteUser: 'Convidar Usuário',
  carrier: 'Transportadora',
  emailOrPhoneNumber: 'Email',
  usersEditor: 'Editor de Usuários',
  verifyPassword: 'Verificar Senha',
  verificationCode: 'Código de Verificação',
  register: 'Registrar',
  registerInstructions: 'Por favor, preencha este formulário para finalizar seu registro.',
  'password-requirements':
    'Sua senha deve ter pelo menos 8 caracteres, conter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial.',
  passwordMinCharacters: 'A senha deve conter 8 ou mais caracteres.',
  passwordMaxCharacters: 'A senha não pode conter mais de 99 caracteres.',
  passwordLowerCase: 'A senha deve incluir 1 ou mais letra(s) minúscula(s).',
  passwordUpperCase: 'A senha deve incluir 1 ou mais letra(s) maiúscula(s).',
  passwordNumber: 'A senha deve incluir 1 ou mais caractere(s) numérico(s).',
  passwordSpecialCharacter: 'A senha deve incluir 1 ou mais caractere(s) especial(ais).',
  passwordsDontMatch: 'As senhas não coincidem!',
  success: 'Sucesso!',
  'registration-success': 'Seu registro foi bem-sucedido.',
  'post-registration-instructions': 'Você completou o processo de registro. Agora você pode fazer login.',
  createDate: 'Data de Criação',
  customerPo: 'Número do Pedido do Cliente',
  specification: 'Especificação',
  description: 'Descrição',
  classType: 'Tipo de Classe',
  minimumValue: 'Valor Mínimo',
  maximumValue: 'Valor Máximo',
  TrackIt: 'TrackIt',
  trackIt3PContext: 'Contexto TrackIt 3P',
  inviteLanguage: 'Idioma do Convite',
  truckNumber: 'Número do Caminhão',
  adverseTemperature:
    'A temperatura ambiente no momento da mistura era tal que não podemos garantir que o concreto estará nas faixas de temperatura de 5-35oC conforme especificado na EN 206.',
  environmentalNote:
    'Esta carga contém um agente ambiental que não excede 0,03% em peso do concreto. A água contendo este material para a fabricação de concreto estará em conformidade com a BS EN 1008.',
  unloadingMethodEnabled: 'Habilitar Método de Descarga',
  warrantyVoided: 'Garantia do Material Anulada',
  materialWarrantyVoidedEnabled:
    'Quando o Produto de Nota de Perda de Certificação ou Código de Produto & Razão é usado, mostrar uma Nota de Perda de Certificação no momento da assinatura.',
  'setup_templates-mt': 'Modelos',
  copyFrom: 'Copiar De...',
  copy: 'copiar',
  users: 'Usuários',
  setup: 'Configuração',
  driverExperience: 'Experiência do Motorista',
  ticketList: 'Lista de Bilhetes',
  content: 'Conteúdo',
  WARNINGS: 'Avisos',
  TERMS: 'Termos',
  DAMAGE: 'Dano',
  ADDITIONALWAIVER: 'Isenção Adicional',
  DYNAMICWAIVER: 'Isenção Dinâmica',
  source: 'Fonte',
  sensorUnable: 'Sensor incapaz de determinar a quantidade restante.',
  PROBE: 'Sonda',
  DRIVER: 'Motorista',
  DRS: 'DRS',
  waterReleaseFormEnabled: 'Habilitar Formulário de Liberação de Água',
  waterRelease: 'Liberação de Água',
  waterReleaseAcknowledged: 'Liberação de Água Reconhecida',
  'assessment-risk': 'Avaliação de Risco',
  'setup_assessment-risk': 'Avaliação de Risco',
  'add-assessment-risk': 'Adicionar Avaliação de Risco',
  enterRecipientsButton: 'Inserir Destinatário(s)',
  recipientsInstructions: 'Inserir Email, separados por vírgula',
  enter: 'Inserir',
  invalidEmailAddress: 'endereço de email inválido',
  personalDataPolicy:
    'Ao inserir dados pessoais neste formulário, você afirma que possui as permissões necessárias para compartilhar esses dados pessoais',
  subcategory: 'Subcategoria',
  isRequired: 'É Necessário',
  riskAssessmentEnabled: 'Habilitar Avaliação de Risco',
  riskAssessment: 'Avaliação de Risco',
  yes: 'Sim',
  no: 'Não',
  sourceOptionsSensors: 'Opções de Fonte (sensores)',
  sourceDcm: 'DCM',
  sourceProbe: 'Sonda',
  availableCustomValues: 'Valores Personalizados Disponíveis',
  ERR_CODE__DUPLICATE: 'Duplicado',
  ERR_CODE__DEFAULT_VALUE: 'Já existe um valor padrão',
  ERR_CODE__EXISTING_FORM: 'Já existe um formulário ativo para este Veículo e Tipo',
  availableValues: 'Valores Disponíveis',
  pump: 'Bomba',
  DanskName: 'Nome Dinamarquês',
  GermanName: 'Nome Alemão',
  EnglishName: 'Nome Inglês',
  BritainName: 'Nome Britânico',
  SpanishName: 'Nome Espanhol',
  FrenchName: 'Nome Francês',
  NederlandsName: 'Nome Holandês',
  NorskName: 'Nome Norueguês',
  SvenskaName: 'Nome Sueco',
  ItalianName: 'Nome Italiano',
  PolishName: 'Nome Polonês',
  DanskNameDefault: 'Nome Dinamarquês (Padrão)',
  GermanNameDefault: 'Nome Alemão (Padrão)',
  EnglishNameDefault: 'Nome Inglês (Padrão)',
  BritainNameDefault: 'Nome Britânico (Padrão)',
  SpanishNameDefault: 'Nome Espanhol (Padrão)',
  FrenchNameDefault: 'Nome Francês (Padrão)',
  NederlandsNameDefault: 'Nome Holandês (Padrão)',
  NorskNameDefault: 'Nome Norueguês (Padrão)',
  SvenskaNameDefault: 'Nome Sueco (Padrão)',
  ItalianNameDefault: 'Nome Italiano (Padrão)',
  PolishNameDefault: 'Nome Polonês (Padrão)',
  DanskDescription: 'Descrição Dinamarquesa',
  GermanDescription: 'Descrição Alemã',
  EnglishDescription: 'Descrição Inglesa',
  BritainDescription: 'Descrição Britânica',
  SpanishDescription: 'Descrição Espanhola',
  FrenchDescription: 'Descrição Francesa',
  NederlandsDescription: 'Descrição Holandesa',
  NorskDescription: 'Descrição Norueguesa',
  SvenskaDescription: 'Descrição Sueca',
  ItalianDescription: 'Descrição Italiana',
  PolishDescription: 'Descrição Polonesa',
  DanskDescriptionDefault: 'Descrição Dinamarquesa (Padrão)',
  GermanDescriptionDefault: 'Descrição Alemã (Padrão)',
  EnglishDescriptionDefault: 'Descrição Inglesa (Padrão)',
  BritainDescriptionDefault: 'Descrição Britânica (Padrão)',
  SpanishDescriptionDefault: 'Descrição Espanhola (Padrão)',
  FrenchDescriptionDefault: 'Descrição Francesa (Padrão)',
  NederlandsDescriptionDefault: 'Descrição Holandesa (Padrão)',
  NorskDescriptionDefault: 'Descrição Norueguesa (Padrão)',
  SvenskaDescriptionDefault: 'Descrição Sueca (Padrão)',
  ItalianDescriptionDefault: 'Descrição Italiana (Padrão)',
  PolishDescriptionDefault: 'Descrição Polonesa (Padrão)',
  DanskContent: 'Conteúdo Dinamarquês',
  GermanContent: 'Conteúdo Alemão',
  EnglishContent: 'Conteúdo Inglês',
  BritainContent: 'Conteúdo Britânico',
  SpanishContent: 'Conteúdo Espanhol',
  FrenchContent: 'Conteúdo Francês',
  NederlandsContent: 'Conteúdo Holandês',
  NorskContent: 'Conteúdo Norueguês',
  SvenskaContent: 'Conteúdo Sueco',
  ItalianContent: 'Conteúdo Italiano',
  PolishContent: 'Conteúdo Polonês',
  DanskContentDefault: 'Conteúdo Dinamarquês (Padrão)',
  GermanContentDefault: 'Conteúdo Alemão (Padrão)',
  EnglishContentDefault: 'Conteúdo Inglês (Padrão)',
  BritainContentDefault: 'Conteúdo Britânico (Padrão)',
  SpanishContentDefault: 'Conteúdo Espanhol (Padrão)',
  FrenchContentDefault: 'Conteúdo Francês (Padrão)',
  NederlandsContentDefault: 'Conteúdo Holandês (Padrão)',
  NorskContentDefault: 'Conteúdo Norueguês (Padrão)',
  SvenskaContentDefault: 'Conteúdo Sueco (Padrão)',
  ItalianContentDefault: 'Conteúdo Italiano (Padrão)',
  PolishContentDefault: 'Conteúdo Polonês (Padrão)',
  DanskCategory: 'Categoria Dinamarquesa',
  GermanCategory: 'Categoria Alemã',
  EnglishCategory: 'Categoria Inglesa',
  BritainCategory: 'Categoria Britânica',
  SpanishCategory: 'Categoria Espanhola',
  FrenchCategory: 'Categoria Francesa',
  NederlandsCategory: 'Categoria Holandesa',
  NorskCategory: 'Categoria Norueguesa',
  SvenskaCategory: 'Categoria Sueca',
  ItalianCategory: 'Categoria Italiana',
  PolishCategory: 'Categoria Polonesa',
  DanskCategoryDefault: 'Categoria Dinamarquesa (Padrão)',
  GermanCategoryDefault: 'Categoria Alemã (Padrão)',
  EnglishCategoryDefault: 'Categoria Inglesa (Padrão)',
  BritainCategoryDefault: 'Categoria Britânica (Padrão)',
  SpanishCategoryDefault: 'Categoria Espanhola (Padrão)',
  FrenchCategoryDefault: 'Categoria Francesa (Padrão)',
  NederlandsCategoryDefault: 'Categoria Holandesa (Padrão)',
  NorskCategoryDefault: 'Categoria Norueguesa (Padrão)',
  SvenskaCategoryDefault: 'Categoria Sueca (Padrão)',
  ItalianCategoryDefault: 'Categoria Italiana (Padrão)',
  PolishCategoryDefault: 'Categoria Polonesa (Padrão)',
  DanskSubcategory: 'Subcategoria Dinamarquesa',
  GermanSubcategory: 'Subcategoria Alemã',
  EnglishSubcategory: 'Subcategoria Inglesa',
  BritainSubcategory: 'Subcategoria Britânica',
  SpanishSubcategory: 'Subcategoria Espanhola',
  FrenchSubcategory: 'Subcategoria Francesa',
  NederlandsSubcategory: 'Subcategoria Holandesa',
  NorskSubcategory: 'Subcategoria Norueguesa',
  SvenskaSubcategory: 'Subcategoria Sueca',
  ItalianSubcategory: 'Subcategoria Italiana',
  PolishSubcategory: 'Subcategoria Polonesa',
  DanskSubcategoryDefault: 'Subcategoria Dinamarquesa (Padrão)',
  GermanSubcategoryDefault: 'Subcategoria Alemã (Padrão)',
  EnglishSubcategoryDefault: 'Subcategoria Inglesa (Padrão)',
  BritainSubcategoryDefault: 'Subcategoria Britânica (Padrão)',
  SpanishSubcategoryDefault: 'Subcategoria Espanhola (Padrão)',
  FrenchSubcategoryDefault: 'Subcategoria Francesa (Padrão)',
  NederlandsSubcategoryDefault: 'Subcategoria Holandesa (Padrão)',
  NorskSubcategoryDefault: 'Subcategoria Norueguesa (Padrão)',
  SvenskaSubcategoryDefault: 'Subcategoria Sueca (Padrão)',
  ItalianSubcategoryDefault: 'Subcategoria Italiana (Padrão)',
  PolishSubcategoryDefault: 'Subcategoria Polonesa (Padrão)',
  priorSignatures: 'Assinaturas Anteriores',
  damageWaiverSignature: 'Assinatura de Isenção de Danos',
  waterReleaseSignature: 'Assinatura de Liberação de Água',
  errors,
  themes,
  isPDFRecipientListEnable: 'Habilitar Lista de Destinatários PDF na Tela de Assinatura do Cliente',
  isRejectLoadsEnable: 'Habilitar Rejeição de Cargas Após Chegada no Local',
  rejectLoad_returnedMaterial: 'Botão de Fluxo de Trabalho de Material Devolvido',
  rejectLoad_returnedAndFinalize: 'Botões de Fluxo de Trabalho de Material Devolvido e Finalizar',
  driverRejection: 'Rejeitar Carga',
  submitRejection: 'Submeter Rejeição',
  costAndPaymentsSummary: 'Resumo de Custos e Pagamentos',
  subtotal: 'Subtotal',
  tax: 'Imposto',
  remainingBalance: 'Saldo Restante',
  paymentAmount: 'Valor do Pagamento',
  total: 'Total',
  paymentMethod: 'Método de Pagamento',
  paymentProducts: 'Produtos',
  unitPrice: 'Preço Unitário',
  amount: 'Quantidade',
  otherPaymentMethod: 'Outro método de pagamento',
  method: 'Método',
  paymentOptions: 'Opções de Pagamento',
  typeAdditionalInfo: 'Digite informações adicionais aqui',
  COD: 'Pagamento na Entrega',
  AMEX: 'American Express',
  CASH: 'Dinheiro',
  CHECK: 'Cheque',
  'CREDIT CARD': 'Cartão de Crédito',
  ACCOUNT: 'Abrir conta',
  UNKNOWN: 'Desconhecido',
  remainingMaterial: 'Material Restante',
  splitLoad: 'Dividir Carga - Múltiplo',
  isEnablePaymentAndCostWorkflows: 'Habilitar Fluxos de Trabalho de Pagamento e Custos',
  airTempValue: 'Temperatura do Ar',
  concreteTempValue: 'Temperatura do Concreto',
  cylinderNumber: 'Número do Cilindro',
  materialOnTruck: 'Material no Caminhão',
  waterLocation: 'Localização da água',
  dotWorkflow: 'Detalhes do lote',
  inspectorSignature: 'Assinatura do inspetor',
  producerSignature: 'Assinatura do produtor',
  addedItems: 'Itens adicionados',
  producerSignatureContact: 'Nome do produtor',
  inspectorSignatureContact: 'Nome do inspetor',
  waterAtPlant: 'Água na planta (gl)',
  waterAtJobsite: 'Água no local de trabalho (gl)',
  totalActualWater: 'Total de água real',
  timeDischarged: 'Tempo de descarga',
  ingredient: 'Ingrediente',
  mcFac: 'MCFac',
  absFac: 'ABSFAC',
  od: 'OD',
  abs: 'ABS',
  ssd: 'SSD',
  freeMst: 'Umidade Livre',
  cydsTarg: 'CYDS Alvo',
  target: 'Alvo',
  actual: 'Real',
  freWat: 'Água Livre',
  trim: 'Aparar',
  batchInformation: 'Informações do Lote',
  numberOfBatches: 'Número de Lotes',
  totalWater: 'Água Total',
  designWater: 'Água de Projeto',
  toAdd: 'Para Adicionar',
  designWC: 'Projeto W/C',
  waterCement: 'Água / Cimento',
  waterInTruck: 'Água no Caminhão',
  adjustWater: 'Ajustar Água',
  trimWater: 'Aparar Água',
  plantWeather: 'Clima da Planta',
  rh: 'RH',
  observationTime: 'Tempo de Observação',
  address: 'Endereço',
  plant: 'Planta',
  sp: 'S.P. #',
  beginTime: 'Hora de Início',
  mixDesc: 'Descrição da Mistura',
  qtyOder: 'Quantidade Pedido',
  poTag: 'PO #',
  bridge: 'Ponte #',
  volume: 'Volume',
  truckTag: 'Caminhão #',
  qtyDelivered: 'Quantidade Entregue',
  qtyOrder: 'Quantidade Pedido',
  job: 'Trabalho #',
  batchRecord: 'Registro do Lote',
  mixID: 'ID da Mistura',
  loads: 'Cargas',
  isDot: 'É DOT',
  tolStatus: 'Status TOL',
  lastUpdate: 'Última atualização',
  driverClickedFinalize: 'Motorista Clicou em Finalizar',
  autoSubmit: 'Enviar automaticamente',
  noTaxInformationIsAvailable: 'Nenhuma informação fiscal está disponível',
  retryPullTaxInformation: 'Atualizar dados fiscais',
  var: '% Var',
  designQTY: 'Design Antal',
  required: 'Nödvändig',
  batched: 'Batchad',
  moisture: 'Fukt',
  actualWater: 'Faktiskt vatten',
  moisturePercent: '% Umidade',
  freeWater: 'Gratis vatten',
  reset: 'Redefinir',
  'at-plant': 'Na fábrica',
  'at-job': 'No trabalho',
};
